import _ from 'lodash';
import { memo, useMemo } from 'react';
import { Area, Bar, CartesianGrid, ComposedChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts-new';
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource';
import dayAPI from '~/utils/dayAPI';
import { useChipData } from './useChipData';
export const ChipDataChart = memo(function ChipDataChart(props) {
    const data = useChipData().twse?.slice(-props.rangeDays);
    //要新增
    //上市 上櫃 期貨 小台
    const timeStart = data?.map(s => s.date)[0];
    const timeEnd = data?.map(s => s.date).slice(-1)[0];
    const range = [dayAPI(timeStart).unix(), dayAPI(timeEnd).add(14, 'hour').unix()];
    const { data: rawData } = useSymbolHistoryResource({
        symbol: 'TSEA',
        fromTo: range,
        resolution: '1D',
    });
    const indexData = useMemo(() => {
        return rawData?.map(datum => ({
            close: datum.close,
        }));
    }, [rawData])?.map(s => s.close);
    const resultData = data?.map((value, index) => ({
        date: value.date,
        foreigner: value.foreigner,
        dealer: value.dealer,
        trust: value.trust,
        close: indexData?.[index],
    }));
    const priceMin = Math.min(..._.map(indexData)) * 0.98;
    const priceMax = Math.max(..._.map(indexData)) * 1.02;
    const step = (priceMax - priceMin) / 4;
    return (<ResponsiveContainer width='100%' height='100%'>
      <ComposedChart data={resultData} margin={{
            top: 8,
            right: 8,
            bottom: 8,
            left: 8,
        }}>
        <CartesianGrid strokeDasharray='2 2'/>
        <XAxis dataKey='date' fontSize={14}/>
        <YAxis fontSize={14}/>
        <YAxis yAxisId={2} orientation='right' domain={[priceMin, priceMax]} fontSize={14} interval={0} ticks={[priceMin, priceMin + step, priceMin + step * 2, priceMin + step * 3, priceMax]}/>
        <Tooltip />
        <defs>
          <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
            <stop offset={2 / 5} stopColor={'#005594'} stopOpacity={1}/>
            <stop offset={4 / 5} stopColor={'#005594'} stopOpacity={0.1}/>
          </linearGradient>
        </defs>
        <Area yAxisId={2} dataKey='close' isAnimationActive={false} dot={false} fillOpacity={0.1} stroke='#2962ff' strokeWidth={1.6} type='monotone' fill='url(#splitColor)'/>
        <ReferenceLine y={0} stroke='#000'/>
        <Bar dataKey='foreigner' fill='#8884d8' stackId='stack' radius={[2, 2, 0, 0]} isAnimationActive={false}/>
        <Bar dataKey='dealer' fill='#82ca9d' stackId='stack' radius={[2, 2, 0, 0]} isAnimationActive={false}/>
        <Bar dataKey='trust' fill='#faca9d' stackId='stack' radius={[2, 2, 0, 0]} isAnimationActive={false}/>
      </ComposedChart>
    </ResponsiveContainer>);
});
