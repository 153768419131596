import { useSnapshot } from 'valtio';
import { useUnifyTableResource } from '~/modules/chips/useChipResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import dayAPI from '~/utils/dayAPI';
const billionTransform = (value) => {
    // //大於1億
    // if (data >= 1e8) {
    //   return Math.round((value / 1e8) * 100) / 100
    // }
    // //大於1千萬 小於1億
    // else if (data >= 1e7 && data < 1e8) {
    //   return Math.round((data / 1e7) * 100) / 100
    // }
    // //大於1百萬 小於1千萬
    // else if (data >= 1e6 && data < 1e7) {
    //   return Math.round((data / 1e6) * 100) / 100
    // }
    // return data
    return Math.round((value / 1e8) * 1000) / 1000;
};
export const useChipData = (rangeDays) => {
    const staticState = useSnapshot(staticStore);
    const todayURL = staticState.tradedDate.day0;
    const days = rangeDays ?? 365;
    const startURL = dayAPI(todayURL).subtract(days, 'day');
    const chips = useUnifyTableResource(dayAPI(startURL).unix(), dayAPI(todayURL).add(15, 'hour').unix());
    //查詢的日期
    const chipsDate = chips?.t.map(s => dayAPI(s * 1000).format('YYYY/MM/DD'));
    //上市:外資 自營商 投信
    const twseForeigner = chips?.foreigner_twse.trading_value_diff ?? [];
    const twseDealer = chips?.dealer_twse.trading_value_diff ?? [];
    const twseTrust = chips?.trust_twse.trading_value_diff ?? [];
    //上櫃:外資 自營商 投信
    const tpexForeigner = chips?.foreigner_tpex.trading_value_diff ?? [];
    const tpexDealer = chips?.dealer_tpex.trading_value_diff ?? [];
    const tpexTrust = chips?.trust_tpex.trading_value_diff ?? [];
    //台指
    const txfForeigner = chips?.foreigner_txf.oi_diff ?? [];
    const txfDealer = chips?.dealer_txf.oi_diff ?? [];
    const txfTrust = chips?.trust_txf.oi_diff ?? [];
    //選擇權
    const optForeigner = chips?.foreigner_opt.oi_value_diff ?? [];
    const optDealer = chips?.dealer_opt.oi_value_diff ?? [];
    const optTrust = chips?.dealer_opt.oi_value_diff ?? [];
    //大額交易人台指期
    const txfLagerTop5 = chips?.large_trader_futures_top5.oi_diff ?? [];
    const txfLagerTop10 = chips?.large_trader_futures_top10.oi_diff ?? [];
    const txfLagerLast5 = chips?.large_trader_futures_last5.oi_diff ?? [];
    //大額交易人選擇權
    const optLagerTop5 = chips?.large_trader_options_top5.oi_diff ?? [];
    const optLagerTop10 = chips?.large_trader_options_top10.oi_diff ?? [];
    const optLagerLast5 = chips?.large_trader_options_last5.oi_diff ?? [];
    //小台指可憐的散戶
    const mtfFatSheep = chips?.retail_oi.oi_diff ?? [];
    //考慮美觀自動補零 => toFixed(2).padEnd(2, '0')
    /** 上市資料 */
    const twseData = chipsDate?.map((value, index) => ({
        date: value,
        foreigner: billionTransform(twseForeigner?.[index] ?? 0),
        dealer: billionTransform(twseDealer?.[index] ?? 0),
        trust: billionTransform(twseTrust?.[index] ?? 0),
        total: billionTransform(twseForeigner?.[index] + twseDealer?.[index] + twseTrust?.[index]),
    }));
    const tpexData = chipsDate?.map((value, index) => ({
        date: value,
        foreigner: billionTransform(tpexForeigner?.[index]),
        dealer: billionTransform(tpexDealer?.[index]),
        trust: billionTransform(tpexTrust?.[index]),
        total: billionTransform(tpexForeigner?.[index] + tpexDealer?.[index] + tpexTrust?.[index]),
    }));
    const txfData = chipsDate?.map((value, index) => ({
        date: value,
        foreigner: txfForeigner?.[index],
        dealer: txfDealer?.[index],
        trust: txfTrust?.[index],
        total: txfForeigner?.[index] + txfDealer?.[index] + txfTrust?.[index],
    }));
    const optData = chipsDate?.map((value, index) => ({
        date: value,
        foreigner: optForeigner?.[index],
        dealer: optDealer?.[index],
        trust: optTrust?.[index],
        total: optForeigner?.[index] + optDealer?.[index] + optTrust?.[index],
    }));
    const txfLagerData = chipsDate?.map((value, index) => ({
        date: value,
        top5: txfLagerTop5?.[index],
        top10: txfLagerTop10?.[index],
        last5: txfLagerLast5?.[index],
    }));
    const optLagerData = chipsDate?.map((value, index) => ({
        date: value,
        top5: optLagerTop5?.[index],
        top10: optLagerTop10?.[index],
        last5: optLagerLast5?.[index],
    }));
    const mtfData = chipsDate?.map((value, index) => ({
        date: value,
        mtf: mtfFatSheep?.[index],
    }));
    return {
        twse: twseData,
        tpex: tpexData,
        txf: txfData,
        opt: optData,
        txfLarger: txfLagerData,
        optLarger: optLagerData,
        mtf: mtfData,
    };
};
