import styled from '@emotion/styled';
import { memo } from 'react';
import { SegmentedControl } from '@mantine/core';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useChipData } from './useChipData';
import { css } from '@emotion/react';
import { ChipDataChart } from './ChipDataChart';
import { OverViewTable } from './OverViewTable';
export const chipStore = proxy({
    stata: 'twse',
    rangeDays: 60,
    chipType: 'main',
});
const NetBuySell = memo(function NetBuySell(props) {
    return (<div css={fill_vertical_all_center}>
      {props.data?.map((value, index) => {
            return (<div key={index} css={fill_vertical_all_center}>
            <div>{value.date}</div>
            <div>{value.foreigner}</div>
          </div>);
        })}
    </div>);
});
export const OverView = memo(function OverView() {
    const staticState = useSnapshot(chipStore);
    const rangeDays = staticState.rangeDays;
    const twseData = useChipData().twse ?? [];
    const tpexData = useChipData().tpex ?? [];
    const twseLastData = twseData.slice(-1)[0];
    const tpexLastData = tpexData.slice(-1)[0];
    const txfLastData = useChipData().txf?.slice(-1)[0];
    const optLastData = useChipData().opt?.slice(-1)[0];
    const txfLargerLastData = useChipData().txfLarger?.slice(-1)[0];
    const optLargerLastData = useChipData().optLarger?.slice(-1)[0];
    const lastDate = twseLastData?.date;
    const max = (data) => {
        const result = data?.sort((a, b) => b.foreigner - a.foreigner).slice(0, 3);
        return result;
    };
    const min = (data) => {
        const result = data?.sort((a, b) => a.foreigner - b.foreigner).slice(0, 3);
        return result;
    };
    const dayData = twseData.slice(-1).concat();
    return (<styleds.container>
      <styleds.title>
        <div>
          <SegmentedControl value={useSnapshot(chipStore).rangeDays.toString()} onChange={value => (chipStore.rangeDays = Number(value))} data={[
            { label: '20天', value: '20' },
            { label: '60天', value: '60' },
            { label: '120天', value: '120' },
            { label: '240天', value: '240' },
        ]}/>
        </div>
      </styleds.title>
      <styleds.chipChartContent>
        {/* <div
          css={css`
            ${fill_horizontal_all_center};
            width: 336px;
            height: 100%;
            background-color: #f3f3f3;
          `}
        >
          前三大買買超日期
          <NetBuySell data={max(twseData)} />
          <NetBuySell data={min(twseData)} />
        </div> */}
        <DailyChip />
        <styleds.chipChart>
          <ChipDataChart rangeDays={rangeDays}/>
        </styleds.chipChart>
      </styleds.chipChartContent>
      <styleds.title>
        <div>市場籌碼概覽</div> <div>資料日期:{lastDate}</div>
      </styleds.title>
      <styleds.chipCardGroup>
        <styleds.chipCard>
          <div>外資</div>
          <div>上市{twseLastData?.foreigner}</div>
          <div>上櫃{tpexLastData?.foreigner}</div>
          <div>期貨{txfLastData?.foreigner}</div>
          <div>選擇權{optLastData?.foreigner}</div>
        </styleds.chipCard>
        <styleds.chipCard>
          <div>自營商</div>
          <div>上市{twseLastData?.dealer}</div>
          <div>上櫃{tpexLastData?.dealer}</div>
          <div>期貨{txfLastData?.dealer}</div>
          <div>選擇權{optLastData?.dealer}</div>
        </styleds.chipCard>
        <styleds.chipCard>
          <div>投信</div>
          <div>上市{twseLastData?.trust}</div>
          <div>上櫃{tpexLastData?.trust}</div>
          <div>期貨{txfLastData?.trust}</div>
          <div>選擇權{optLastData?.trust}</div>
        </styleds.chipCard>
        <styleds.chipCard>
          <div>前五大交易人</div>
          <div>期貨{txfLargerLastData?.top5}</div>
          <div>選擇權{optLargerLastData?.top5}</div>
        </styleds.chipCard>
        <styleds.chipCard>
          <div>前十大交易人</div>
          <div>期貨{txfLargerLastData?.top10}</div>
          <div>選擇權{optLargerLastData?.top10}</div>
        </styleds.chipCard>
        <styleds.chipCard>
          <div>後五大交易人</div>
          <div>期貨{txfLargerLastData?.last5}</div>
          <div>選擇權{optLargerLastData?.last5}</div>
        </styleds.chipCard>
      </styleds.chipCardGroup>

      <styleds.title>
        <div>三大法人買賣超彙總表</div>
        <div>
          <SegmentedControl value={useSnapshot(chipStore).stata} onChange={value => (chipStore.stata = value)} data={[
            { label: '集中市場', value: 'twse' },
            { label: '櫃買市場', value: 'tpex' },
            { label: '台指期貨', value: 'txf' },
            { label: '台指選擇權', value: 'opt' },
        ]}/>
        </div>
      </styleds.title>

      <styleds.chipTable>
        <OverViewTable />
      </styleds.chipTable>
    </styleds.container>);
});
const DailyChip = memo(function DailyChip() {
    const twseData = useChipData().twse ?? [];
    const tpexData = useChipData().tpex ?? [];
    const twseLastData = twseData.slice(-1)[0];
    const tpexLastData = tpexData.slice(-1)[0];
    return (<div css={css `
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-template-rows: repeat(5, 20%);
        width: 336px;
        height: 100%;
        padding: 8px;
      `}>
      <p css={css `
          grid-column: 1 / 5;
          grid-row: 1;
        `}>
        {twseLastData?.date}今日法人進出
      </p>
      <p css={css `
          grid-column: 2;
          grid-row: 2;
        `}>
        外資
      </p>
      <p css={css `
          grid-column: 3;
          grid-row: 2;
        `}>
        投信
      </p>
      <p css={css `
          grid-column: 4;
          grid-row: 2;
        `}>
        自營
      </p>
      <p css={css `
          grid-column: 1;
          grid-row: 3;
        `}>
        上市
      </p>
      <p css={css `
          grid-column: 1;
          grid-row: 4;
        `}>
        上櫃
      </p>
      <p css={css `
          grid-column: 2;
          grid-row: 3;
        `}>
        {twseLastData?.foreigner}
      </p>
      <p css={css `
          grid-column: 3;
          grid-row: 3;
        `}>
        {twseLastData?.dealer}
      </p>
      <p css={css `
          grid-column: 4;
          grid-row: 3;
        `}>
        {twseLastData?.trust}
      </p>
      <p css={css `
          grid-column: 2;
          grid-row: 4;
        `}>
        {tpexLastData?.foreigner}
      </p>
      <p css={css `
          grid-column: 3;
          grid-row: 4;
        `}>
        {tpexLastData?.dealer}
      </p>
      <p css={css `
          grid-column: 4;
          grid-row: 4;
        `}>
        {tpexLastData?.trust}
      </p>
    </div>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
  `,
    title: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    width: 100%;
    height: 40px;
    background-color: #f3f3f3;
  `,
    chipCardGroup: styled.div `
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 16px;
    padding: 16px;
  `,
    chipCard: styled.div `
    ${fill_vertical};
    min-width: 336px;
    max-width: 32%;
    height: 132px;
    background-color: #f3f3f3;
    border-radius: 8px;
  `,
    chipChartContent: styled.div `
    ${fill_horizontal_all_center};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 280px;
  `,
    chipChart: styled.div `
    min-width: 336px;
    width: calc(99% - 336px);
    height: 100%;
  `,
    chipTable: styled.div `
    width: 100%;
  `,
};
